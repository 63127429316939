import React, { useCallback } from "react";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { ApiUrl } from "../../../components/API/Api";
import { useLocation } from "react-router-dom";
// import { FaEye } from "react-icons/fa";
import CircularProgress from "@mui/material/CircularProgress";

const Diocese = () => {
  // const navigate = useNavigate();
  const [data, Setdata] = useState([]);
  const [filterddata, Filterdata] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const CategoryFromUrlId = searchParams.get("from");
  const [loading, setLoading] = useState(true);
  const fetchData = useCallback(() => {
    fetch(`${ApiUrl}/get/chengaidiocese/preist/${parseInt(CategoryFromUrlId)}`)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        Setdata(resp?.data);
        Filterdata(resp?.data);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [CategoryFromUrlId, Setdata, Filterdata]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      width: "70px",
    },
    {
      name: "Image",
      cell: (row) => (
        <img
          src={`${row.image === null
            ? "images/all-img/Catholic-Priest.png"
            : row.image
            }`}
          alt="Diocesan Priestimage"
          style={{ width: "80px", height: "87px" }}
        />
      ),
      width: "100px",
      sortable: true,
    },
    {
      name: "Priest Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Ordination Date",
      selector: (row) => row.date_of_ordination,
      sortable: true,
    },
    {
      name: "Ministry",
      selector: (row) => row.ministry,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    // {
    //   name: "Action",
    //   cell: (row) => (
    //     <>
    //       <div
    //         onClick={(e) => viewDiocesepreiest(row.id)}
    //         style={{ cursor: "pointer", paddingRight: 4, color: "black", textDecoration: "none" }}
    //         role="button"
    //         tabIndex="0"
    //       >
    //         <FaEye /> View
    //       </div>
    //     </>
    //   ),

    //   width: "100px",
    // },
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: "52px",
        backgroundColor: "#fafafa",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontSize: "15px",
        fontFamily: "Montserrat",
        backgroundColor: "#912849",
        color: "white",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontFamily: "Montserrat",
        fontSize: "14px",
      },
    },
    pagination: {
      style: {
        fontWeight: "700",
        color: "black",
        fontFamily: "Montserrat",
      },
    },
  };
  function filterdata(event) {
    var value = event.target.value;
    const keys = [
      "name",
      "date_of_ordination",
      "date_of_birth",
      "ministry",
      "email",
    ];
    const filter = filterddata?.filter((item) =>
      keys.some((key) =>
        item[key]?.toString()?.toLowerCase()?.includes(value?.toLowerCase())
      )
    );

    Setdata(filter);
  }
  // const viewDiocesepreiest = async (id) => {
  //   navigate(`/priestmoredetails?di=${id}`);
  // };
 
  return (
    <div className="container">
      <div className="page-header">
        <h2 class="heading">Diocese Priest</h2>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <input
            id="myInput"
            type="text"
            onChange={filterdata}
            className="form-control myInput"
            placeholder="Search.."
          />
        </div>
      </div>
      <br></br>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
            {loading && (
          <CircularProgress
            sx={{ display: "block", margin: "auto", marginTop: "2rem" }}
            />
          )}
              <DataTable
                columns={columns}
                theme="solarized"
                data={data}
                pagination
                progressPending={loading}
                customStyles={customStyles}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Diocese;
