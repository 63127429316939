import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ApiUrl } from "../../components/API/Api";
import CircularProgress from "@mui/material/CircularProgress";
import DataTable from "react-data-table-component";
// import { FaEye } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";

const ParishesNew = () => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [filterddata, setfilterData] = useState([]);
  const [categoryName, setCategoryName] = useState("Vicariate Details");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const navigate = useNavigate();

  const searchParams = new URLSearchParams(location?.search);
  const CategoryFromUrlId = searchParams.get("from");

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      axios
        .get(`${ApiUrl}/get/chengaidiocese/parish/${parseInt(CategoryFromUrlId)}`)
        .then((response) => {
          setLoading(false);
          setData(response?.data?.data || []);
          setfilterData(response?.data?.data || []);
          if (response?.data?.data?.length > 0) {
            if (CategoryFromUrlId !== "parish") {
              setCategoryName(response.data.data[0].category_name);
            } else {
              setCategoryName("PARISHES");
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          setError(error);
        });
    };

    fetchData();
  }, [CategoryFromUrlId]);

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      width: "70px",
    },
    {
      name: "Image",
      cell: (row) => (
        <img
          src={`${row.parishimage === null
            ? "images/all-img/Catholic-Priest.png"
            : row.parishimage
            }`}
          style={{ height: "90px", width: "90px",boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",borderRadius: "10px",marginTop: "10px", marginBottom: "10px" }}
          alt="nodata"
        />
      ),
    },
    {
      name: "Parish Name",
      selector: (row) => row.parish_name,
      sortable: true,
    },
    {
      name:"Parish Priest",
      selector: (row) => row.parish_priest,
      sortable: true,
    },
    {
      name: "Vicariate Name",
      selector: (row) => row.VicariatesName,
      sortable: true,
    },
    {
      name: "Year of Establishment",
      selector: (row) => row.established_year,
      sortable: true,
    },
    // {
    //   name: "Action",
    //   cell: (row) => (
    //     <>
    //       <div
    //         onClick={(e) => viewDiocesepreiest(row.id)}
    //         style={{ cursor: "pointer", paddingRight: 4, color: "black", textDecoration: "none" }}
    //         role="button"
    //         tabIndex="0"
    //       >
    //         <FaEye /> View
    //       </div>
    //     </>
    //   ),

    //   width: "100px",
    // },
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: "52px",
        backgroundColor: "#fafafa",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontSize: "15px",
        fontFamily: "Montserrat",
        backgroundColor: "#912849",
        color: "white",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontFamily: "Montserrat",
        fontSize: "14px",
      },
    },
    pagination: {
      style: {
        fontWeight: "700",
        color: "black",
        fontFamily: "Montserrat",
      },
    },
  };
  // const viewDiocesepreiest = async (id) => {
  //   navigate(`/parishmoredetails?di=${id}`);
  // };

  function filterdata(event) {
    var value = event.target.value;
    const keys = [
      "parish_name",
      "VicariatesName",
      "established_year",
      "address",
      "email",
    ];
    const filter = filterddata?.filter((item) =>
      keys.some((key) =>
        item[key]?.toString()?.toLowerCase()?.includes(value?.toLowerCase())
      )
    );

    setData(filter);
  }
  return (
    <>
      <div className="container">
        {loading && (
          <CircularProgress
            sx={{ display: "block", margin: "auto", marginTop: "2rem" }}
          />
        )}
        {!loading && !error && (
          <>
            {data.length === 0 ? (
              <div className="center-message">
                <p>No Data Available.</p>
              </div>
            ) : (
              <div>

                <h3 className="heading">{categoryName}</h3>
                <div className="row mb-4">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    <input
                      id="myInput"
                      type="text"
                      onChange={filterdata}
                      className="form-control myInput"
                      placeholder="Search.."
                    />
                  </div>
                </div>
                <DataTable
                  columns={columns}
                  theme="solarized"
                  data={data}
                  pagination
                  progressPending={loading}
                  customStyles={customStyles}
                  paginationPerPage={10}
                />
              </div>
            )}
          </>
        )}



        {error && (
          <div className="center-message">
            <p>Error fetching data: {error.message}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default ParishesNew;
